import HackathonsMenu from "./HackathonsMenu";

// Hackathons component
const Hackathons = () => {
  return (
    <>
      <HackathonsMenu />
    </>
  );
};

export default Hackathons;
