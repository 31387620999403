import ProjectsMenu from "./ProjectsMenu";

// Projects component
const Projects = () => {
  return (
    <>
      <ProjectsMenu />
    </>
  );
};

export default Projects;
