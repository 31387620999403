const subheadingsData = {
  1: [
    {
      title: "About Me",
      content: (
        <>
          <p>
            Frontend Developer with a solid grasp of fullstack technologies. My
            journey from a gaming coach to tech enthusiast has equipped me with
            unique problem-solving skills and a eye for detail. I specialize in
            creating responsive web applications.
          </p>
        </>
      ),
    },
  ],
  2: [
    {
      title: "2024 Full Stack Developer Diploma",
      content: (
        <>
          <p>
            Code Institute, full spectrum of web development, from the
            fundamentals of HTML, CSS, and JavaScript to advanced topics in
            Python, Django, and advanced frontend using React. The curriculum
            was structured to ensure a comprehensive understanding of both
            frontend and backend technologies, enabling me to build dynamic,
            responsive websites and applications from scratch.
          </p>
        </>
      ),
    },
    {
      title: "2023 Self-taught Developer",
      content: (
        <>
          <p>
            As I reflect on my journey in 2023, I am proud of the progress I've
            made and the resilience I've shown in the face of challenges. Being
            a self-taught developer has taught me more than just coding; it has
            instilled in me a mindset of continuous learning and adaptation,
            qualities that are indispensable in the ever-changing tech industry.
          </p>
        </>
      ),
    },
  ],
  3: [
    {
      title: "2023 Content Creator & Coach at aimlab.gg",
      content: (
        <>
          <p>
            aimlab.gg emerged as a unique fusion of a forum and coaching
            service, inspired by the interactive nature of Reddit forums, but
            with a distinctive twist: coaches could respond to student or client
            queries through personalized video responses.
          </p>
        </>
      ),
    },
    {
      title: "2022 Ghostwriter for ProGuides YouTube Channel",
      content: (
        <>
          <p>
            I worked to ensure that each script was not only factually accurate
            but also infused with a narrative that resonated with both new
            players and seasoned veterans. My approach was to weave storytelling
            elements with hard-hitting tips and tricks, creating a narrative
            that was both educational and entertaining.
          </p>
        </>
      ),
    },
    {
      title: "2019 Valorant & Fornite Coach",
      content: (
        <>
          <p>
            Each success story was a testament to the hard work, dedication, and
            trust between me and my 2500+ clients/students. However, the role
            was not without its challenges. Keeping up with the ever-evolving
            game meta, managing diverse player personalities, and maintaining
            motivation during tough times required constant effort and
            innovation.
          </p>
        </>
      ),
    },
  ],
};

export default subheadingsData;
